const getProperDEVEnvironementUrl = () => {

    const originalUrl =  window.location.href

    if(originalUrl.includes("prerelease") ){

        return {
            frontUrl : 'https://wonderful-field-0ef6fb003-prerelease.westeurope.3.azurestaticapps.net',
            apiUrl : 'https://app-prescriptions-dev-westeu-poc.azurewebsites.net/'
        };

    } else if (originalUrl.includes("localhost")){

        return {
            frontUrl : 'http://localhost:4201',
            apiUrl : 'https://app-prescriptions-dev-westeu.azurewebsites.net/api/'
        };
    
    } else {

        return {
            frontUrl : 'https://prescriptions-dev.clinicabaviera.com/',
            apiUrl : 'https://app-prescriptions-dev-westeu.azurewebsites.net/api/'
        };
    }
}

export const environment = {

    production: false,

    mobileViewBreakPoint: 850,

    environmentProtocol:'https://',

    environmentDomain: window.location.hostname === 'localhost' ? 'http://localhost:4201' : '-dev.clinicabaviera.com',

    prescriptionsService : {

        url: getProperDEVEnvironementUrl().apiUrl,

        clientId: '9b2c420a-19cb-4d68-8213-8cf55a2f5358', 

        authority: 'https://login.microsoftonline.com/cb4bec43-26c0-4837-bf6c-ab98d284af38',
        
        redirectUri: getProperDEVEnvironementUrl().frontUrl,
        
        logoutRedirectUri: getProperDEVEnvironementUrl().frontUrl,  
        
        scopes:[
            'profile',
            'openid' , 
            '9b2c420a-19cb-4d68-8213-8cf55a2f5358/prescriptions'
        ],
    },

    usersService : {
        url:'https://app-users-dev-westeu.azurewebsites.net/api/',  
        scopes:[
            '05a35cc1-faee-44e0-b1e4-0cff3f341f0d/users',
            'profile',
            'openid' 
        ],
        clientId:'05a35cc1-faee-44e0-b1e4-0cff3f341f0d',  
    },

    patientsService : {
        url: window.location.hostname === 'localhost' ? 'https://localhost:7203/api/' : 'https://app-patients-dev-westeu.azurewebsites.net/api/',
        scopes:[
            '3791ec47-7d67-4014-ab62-228a8a23fcb3/patients',
            'profile',
            'openid' 
        ],
        clientId:'3791ec47-7d67-4014-ab62-228a8a23fcb3',
    },

    masterDataService : {
        url:'https://app-masterdata-dev-westeu.azurewebsites.net/api/',
        scopes:[
            '8e1b917a-9d3c-46c5-8a79-216cd8d1b5d1/master-data',
            'profile',
            'openid' 
        ],
        clientId:'8e1b917a-9d3c-46c5-8a79-216cd8d1b5d1'  
    }
};
