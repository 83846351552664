import { favoritesMasterFormulasEffects } from "./effects/medicaments/favorites-masterFormulas.effects";
import { favoritesMedicamentsEffects } from "./effects/medicaments/favorites-medicaments.effects";
import { favoritesParapharmaciesEffects } from "./effects/medicaments/favorites-parafarmacies.effects";
import { ParaPrescriptionEffects } from "./effects/paraPrescriptions/paraPrescription.effects";
import { PatientsEffects } from "./effects/prescriptions/patients.effects";
import { prescriptionsEffects } from "./effects/prescriptions/prescriptions.effects";
import { protocolsEffects } from "./effects/prescriptions/protocols.effects";
import { ProfessionalsJsonEffects } from "./effects/professionals/professionalsJson.effects";


export const metaEffects = [
    protocolsEffects,
    prescriptionsEffects,
    ParaPrescriptionEffects,
    PatientsEffects,
    ProfessionalsJsonEffects,
    favoritesMedicamentsEffects,
    favoritesMasterFormulasEffects,
    favoritesParapharmaciesEffects
]