import { Component ,  HostListener , OnInit , OnDestroy} from '@angular/core';
import { Subject , Subscription, takeUntil } from 'rxjs';
import { AuthComponent } from './auth/auth.component';
import { Store } from '@ngrx/store';
import { ProfessionalService } from './prescriptions/services/httpRequests/professional.service';
import { addUser, removeFirstAssignementId } from './store/actions/users/user.actions';
import { ComponentToSpinnerService, HandleHTTPerrorsService, ProfessionalHelperService } from './shared/services';
import { HttpErrorResponse } from '@angular/common/http';
import { AppState, ProfessionalsJSONRes, UserInfo, UserRes } from './models';
import { 
  faEye , IconDefinition
} from '@fortawesome/free-solid-svg-icons';

import { getProfessionalsJsonAction } from './store/actions/professionals/professionals-actions';
import { WindowResizeService } from './shared/services/customs/window-resize.service';
import { environment } from 'src/environments/environment';
// import { professionalsFromGoingUpInit } from './mock/professionals/professionalsFromGoingUp.mocks';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [AuthComponent]
})

export class AppComponent implements OnInit , OnDestroy {
  
  title                                         = 'prescriptions';
  isIframe                                      = false;
  logged                                        = false;
  resizeSubscription:                           Subscription;
  userActivity:                                 ReturnType<typeof setTimeout>;
  userDestroyed$:                               Subject<void> = new Subject<void>();
  faEye:                                        IconDefinition = faEye;
  professionalsJsonDestroyed$:                  Subject<void> = new Subject<void>();
  userLogged:                                   UserInfo = {};
  professionalsJSONRes:                         ProfessionalsJSONRes /* = professionalsFromGoingUpInit */;
  windowWidth=                                  0;
  mobileViewBreakPoint=                         environment.mobileViewBreakPoint;

  constructor(

    private store:                              Store<AppState>, 
    private professionalService:                ProfessionalService,
    private componentToSpinnerService:          ComponentToSpinnerService,
    private windowResizeService :               WindowResizeService,
    private handleHTTPerrorsService:            HandleHTTPerrorsService,
    private professionalHelperService:          ProfessionalHelperService
  ){

    this.setTimeout();

    this.resizeSubscription = this.windowResizeService.getWindowWidth().subscribe(width => {
      
      this.windowWidth = width;

      // this.updateColumnVisibility(width);
    });
  }

  ngOnInit(): void {
    this.FillProfessionalInfo();
    this.StoreDoctorsJson();
  }

  FillProfessionalInfo():void{
    
    this.store.select(appState => appState.User).pipe(takeUntil( this.userDestroyed$ )).subscribe((user) => {
      this.userLogged = user;

      const {username , professionalLoggedId} = user || {};
      if(username){
        
        this.logged = true;

        if(!(user as UserInfo).userRole){
          
          // setTimeout(() => {
            this.professionalService.LoadUserByEmail(username).subscribe((res : UserRes)=>{
            
              if(res){
  
                this.store.select( AppState => AppState.professionalsJSON ).pipe(takeUntil( this.professionalsJsonDestroyed$ ))
                .subscribe(( professionalsJson : ProfessionalsJSONRes ) => {
                  
                  const { payload , success } = professionalsJson || {};
  
                  if( success ){
  
                    const { avatar , username } = this.userLogged || {};
                    if(!avatar && username){
            
                      const userAvatar = this.professionalHelperService.AddAvatarByEmail( username , payload );
                      
                      const {role , clinics , documentNumber , phoneNumber , gender , active , id , professionalClinics} = res || {};
  
                      const info = {
                        ...user ,userRole:role ,clinics : clinics , documentNumber: documentNumber , professionalClinics : professionalClinics,
                        phoneNumber : phoneNumber , gender : gender , active : active , professionalLoggedId : id , avatar : userAvatar
                      };
                      this.componentToSpinnerService.sendMessage('hide');
  
                      this.store.dispatch(addUser(info));
                    }
                  }
    
                })
              } else {
  
                if( professionalLoggedId ) this.store.dispatch(removeFirstAssignementId({ order:'remove_first_assignement' }))
              }
            },(error: HttpErrorResponse) => {
              
            })
          // }, 3000);

        }
      } else {
        this.logged = false;
      }
    })
  }

  StoreDoctorsJson():void{
    this.store.select( AppState => AppState.professionalsJSON ).pipe(takeUntil( this.professionalsJsonDestroyed$ ))
    .subscribe(( professionalsJson : ProfessionalsJSONRes ) => {
      this.professionalsJSONRes = professionalsJson;
      const { payload } = professionalsJson || {};

      if(payload.length < 1){
        
        this.store.dispatch( getProfessionalsJsonAction({ professionalsFilters : {} }) );
      } /* else {

        const { avatar , username } = this.userLogged || {};
        if(!avatar && username){

          const userAvatar = this.professionalHelperService.AddAvatarByEmail( username , payload );

          this.store.dispatch(addProfessionalAvatar({ avatar : userAvatar }));
        }
      } */
    })
  }

  RecibeFrame(iframe:boolean):void{
    this.isIframe = iframe;
  }

  setTimeout() {
    //this.userActivity = setTimeout(() => this.authComponent.Logout(),900000);
  }

  isLogged(e:boolean):void{
    this.logged = e;
  }

  @HostListener('window:mousemove') refreshUserState() {
    //clearTimeout(this.userActivity);
    //this.setTimeout();
  }

  ngOnDestroy(): void {
    this.resizeSubscription.unsubscribe();
    this.userDestroyed$.next();
    this.userDestroyed$.complete();
    this.professionalsJsonDestroyed$.next();
    this.professionalsJsonDestroyed$.complete();
  }
}
