import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProfessionalsJSONRes } from 'src/app/models';

@Injectable({
  providedIn: 'root'
})
export class ProfessionalService {

  constructor(private http : HttpClient) {}

  GetProfessionalsJson():Observable<ProfessionalsJSONRes> {
    return this.http.get<ProfessionalsJSONRes>('https://app4.aier.global/platform/api-goingup/doctors?api_key=427116e32a26d646ed4ec9ec6c4158fa0af9f2dbe5ee4d6c116df6c52bf0bdfa');
  }
}
